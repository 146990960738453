















































import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default defineComponent({
  name: 'uOttawa1321Lab3TLCPlate',
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        language: 'en',
      },
      stereoisomerScheme: [] as File[],
      questions: [
        {
          en: 'Please use the field below to upload a scheme analysing the relative stereochemistry of the 4 potential products, indicating which pairs are enantiomers which pairs are diastereomers, identify any meso compounds etc. In your scheme, label each isomer as R,R; S,S; R,S; or S,R.',
          fr: 'Veuillez utiliser le champ ci-dessous pour télécharger un schéma analysant la stéréochimie relative des 4 produits potentiel, en indiquant quelles paires sont des énantiomères, quelles paires sont des diastéréoisomères, identifier tout composé méso, etc. Dans votre schéma, étiquetez chaque isomère comme R,R ; S,S ; R,S ; ou S,R.',
        },
      ],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
  computed: {
    attachments(): File[] {
      return [...this.stereoisomerScheme];
    },
  },
});
